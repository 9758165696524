import Vue from 'vue';
import * as Sentry from '@sentry/browser';
import * as SentryTracing from '@sentry/tracing';
import * as SentryIntegrations from '@sentry/integrations';

const integrations = [
  ...Sentry.defaultIntegrations,
  new SentryTracing.BrowserTracing(),
  new SentryIntegrations.RewriteFrames(),
  new SentryIntegrations.ExtraErrorData({ depth: 3 }),
  new SentryIntegrations.Vue({ Vue, attachProps: true }),
];

const ignoreErrors = [
  // Random plugins/extensions
  'top.GLOBALS',
  // See: http://blog.errorception.com/2012/03/tale-of-unfindable-js-error. html
  'originalCreateNotification',
  'canvas.contentDocument',
  'MyApp_RemoveAllHighlights',
  'http://tt.epicplay.com',
  "Can't find variable: ZiteReader",
  'jigsaw is not defined',
  'ComboSearch is not defined',
  'http://loading.retry.widdit.com/',
  'atomicFindClose',
  // Facebook borked
  'fb_xd_fragment',
  // ISP "optimizing" proxy - `Cache-Control: no-transform` seems to
  // reduce this. (thanks @acdha)
  'bmi_SafeAddOnload',
  'EBCallBackMessageReceived',
  // See http://toolbar.conduit.com/Developer/HtmlAndGadget/Methods/JSInjection.aspx
  'conduitPage',
];

const denyUrls = [
  // Facebook flakiness
  /graph\.facebook\.com/i,
  // Facebook blocked
  /connect\.facebook\.net\/en_US\/all\.js/i,
  // Woopra flakiness
  /eatdifferent\.com\.woopra-ns\.com/i,
  /static\.woopra\.com\/js\/woopra\.js/i,
  // Chrome extensions
  /extensions\//i,
  /^chrome:\/\//i,
  /^chrome-extension:\/\//i,
  // Other plugins
  /127\.0\.0\.1:4001\/isrunning/i, // Cacaoweb
  /webappstoolbarba\.texthelp\.com\//i,
  /metrics\.itunes\.apple\.com\.edgesuite\.net\//i,
];

function setUserContext() {
  Sentry.configureScope((scope) => {
    if (gon && gon.user) {
      scope.setUser({
        id: gon.user.id,
        email: gon.user.email,
        company: gon.user.company,
      });
    } else {
      scope.setUser();
    }
  });
}

Sentry.onLoad(() => {
  Sentry.init({
    dsn: gon.global && gon.global.sentryPublicDsn ? gon.global.sentryPublicDsn : null,
    release: gon.global.version,
    environment: gon.global.environment,
    autoSessionTracking: false,
    // Capture 50% of transactions for performance monitoring.
    tracesSampleRate: 0.5,
    denyUrls,
    ignoreErrors,
    integrations,
  });

  setUserContext();
});
